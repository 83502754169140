import React from "react";
import SEO from "../../components/common/seo.js";
import Layout from "../../components/common/layout";
import Navigation from "../../components/navigation/navigation";
import Header from "../../components/sections/header";
import Map from "../../components/sections/map";
import Contact from "../../components/sections/contact";
import { Dot, Container } from "../../components/global";
import styled from "styled-components";

const PrawoKolejowe = () => (
  <Layout>
    <SEO
      title='Prawo Kolejowe'
      description='Jedną z unikatowych specjalizacji Kancelarii jest także doradztwo spółkom kolejowym w zakresie obowiązujących je regulacji, tak na gruncie krajowym, jak i europejskim. Pomoc prawna Kancelarii obejmuje m.in. pierwsze w Polsce opinie i rozstrzygnięcia w zakresie dopuszczalności i możliwości różnicowania cen biletów kolejowych w przewozach pasażerskich w zależności od kanału dystrybucji (dopuszczalność stosowania innych cen biletów w ramach tradycyjnych kanałów sprzedaży [kasy biletowe] i elektronicznych kanałów sprzedaży [Internet, aplikacje na telefon, automaty biletowe]). Doradztwo Kancelarii obejmuje m.in. regulacje z zakresu:'
    />
    <Navigation />
    <Header />
    <Article id='page'>
      <Title>Prawo Kolejowe</Title>
      <Container>
        <p>
          Jedną z unikatowych specjalizacji Kancelarii jest także doradztwo
          spółkom kolejowym w zakresie obowiązujących je regulacji, tak na
          gruncie krajowym, jak i europejskim. Pomoc prawna Kancelarii obejmuje
          m.in. pierwsze w Polsce opinie i rozstrzygnięcia w zakresie
          dopuszczalności i możliwości różnicowania cen biletów kolejowych w
          przewozach pasażerskich w zależności od kanału dystrybucji
          (dopuszczalność stosowania innych cen biletów w ramach tradycyjnych
          kanałów sprzedaży [kasy biletowe] i elektronicznych kanałów sprzedaży
          [Internet, aplikacje na telefon, automaty biletowe]). Doradztwo
          Kancelarii obejmuje m.in. regulacje z zakresu:
        </p>
        <List>
          <Dot>ustawy o publicznym transporcie zbiorowym;</Dot>
          <Dot>ustawy o transporcie kolejowym;</Dot>
          <Dot>Prawa przewozowego;</Dot>
          <Dot>
            ustawy o uprawnieniach do ulgowych przejazdów środkami publicznego
            transportu zbiorowego;
          </Dot>
          <Dot>
            rozporządzenia (WE) nr 1371/2007 Parlamentu Europejskiego i Rady
            dotyczącego praw i obowiązków pasażerów w ruchu kolejowym;
          </Dot>
          <Dot>
            rozporządzenia (WE) nr 1370/2007 Parlamentu Europejskiego i Rady
            dotyczącego usług publicznych w zakresie kolejowego i drogowego
            transportu pasażerskiego oraz uchylające rozporządzenia Rady (EWG)
            nr 1191/69 i (EWG) nr 1107/70;
          </Dot>
          <Dot>
            ustawy o komercjalizacji i restrukturyzacji przedsiębiorstwa
            państwowego "Polskie Koleje Państwowe”.
          </Dot>
        </List>
      </Container>
    </Article>
  </Layout>
);

export default PrawoKolejowe;

const Title = styled.h4`
  margin-top: 75px;
`;

const List = styled.ul`
  padding: 40px 0px 150px;
  li {
    list-style-position: inside;
    text-indent: -1.5em;
    padding-left: 1.5em;
    line-height: 1.5;
  }
`;
const Article = styled.article`
  padding-top: 20px;
  /* border: 1px solid red; */
`;
